exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-book-printing-js": () => import("./../../../src/pages/book-printing.js" /* webpackChunkName: "component---src-pages-book-printing-js" */),
  "component---src-pages-book-publishing-process-and-steps-js": () => import("./../../../src/pages/book-publishing-process-and-steps.js" /* webpackChunkName: "component---src-pages-book-publishing-process-and-steps-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-example-author-page-js": () => import("./../../../src/pages/example-author-page.js" /* webpackChunkName: "component---src-pages-example-author-page-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-solutions-js": () => import("./../../../src/pages/our-solutions.js" /* webpackChunkName: "component---src-pages-our-solutions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-template-author-page-template-js": () => import("./../../../src/template/author-page-template.js" /* webpackChunkName: "component---src-template-author-page-template-js" */),
  "component---src-template-book-template-js": () => import("./../../../src/template/book-template.js" /* webpackChunkName: "component---src-template-book-template-js" */),
  "component---src-template-books-page-template-js": () => import("./../../../src/template/books-page-template.js" /* webpackChunkName: "component---src-template-books-page-template-js" */)
}

